// import Box from "@material-ui/core/Box"
// import Slider from "@material-ui/core/Slider"
// import Step from '@material-ui/core/Step'
// import { StepIconProps } from '@material-ui/core/StepIcon'
// import StepLabel from '@material-ui/core/StepLabel'
// import { createStyles, withStyles, makeStyles, Theme } from "@material-ui/core/styles"
// import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
// import clsx from 'clsx'
// import { range } from "d3"
// import React from "react"

// interface StyleProps {
//     isVisible: boolean
// }

// const useStyles = makeStyles((_: Theme) =>
//     createStyles({
//         container: {
//             width: '100%',
//             marginBottom: 40,
//         },
//         root: {
//             position: 'relative',
//             left: '50%',
//             transform: 'translateX(-50%)',
//             width: '112%',
//             marginTop: -4,
//             paddingTop: 0,
//             paddingLeft: 0,
//             paddingRight: 0,
//             backgroundColor: 'transparent',
//         },
//         instructions: {
//             marginTop: _.spacing(1),
//             marginBottom: _.spacing(1),
//         },
//         label: {
//             fontSize: "0.8125rem",
//             color: "#666",
//             "& svg": {
//                 width: "0.9rem",
//                 marginTop: "-2px",
//                 verticalAlign: "middle",
//             },
//         },
//         likertwrap: {
//             position: "relative",
//             marginTop: "1.25rem",
//             marginBottom: "0",
//         },
//         labels: {
//             display: "flex",
//             justifyContent: "space-between",
//             // margin: "0#",
//             "&:before": {
//                 content: "''",
//                 position: "absolute",
//                 zIndex: "10",
//                 top: "150%",
//                 left: "0",
//                 width: "100%",
//                 height: "1.5rem",
//             },
//         },
//         label2: {
//             position: 'absolute',
//             left: '50%',
//             transform: 'translateX(-50%)',
//             zIndex: 3,
//             fontSize: 14,
//             '& .MuiStepLabel-label': {
//                 position: 'absolute',
//                 left: '50%',
//                 bottom: '-46px',
//                 transform: 'translateX(-50%)',
//                 color: '#999',
//             },
//             '& .MuiStepLabel-label.MuiStepLabel-completed': {
//                 color: '#999',
//             },
//             '& .MuiStepLabel-label.MuiStepLabel-active': {
//                 color: '#4FA9BA'
//             }
//         },
//         labelActive: {
//             color: '#4FA9BA'
//         },
//     }),
// )

// const CustomSlider = withStyles({
//     root: {
//         color: '#4FA9BA',
//         marginLeft: '8px',
//         width: 'calc(100% - 16px)',
//         height: 15,
//     },
//     mark: {
//         display: 'none'
//     },
//     thumb: {
//         height: 16,
//         width: 16,
//         backgroundColor: '#fff',
//         border: '3px solid currentColor',
//         marginTop: 0,
//         marginLeft: -8,
//         '&:focus, &:hover, &$active': {
//             boxShadow: 'none',
//         },
//     },
//     active: {},
//     valueLabel: {
//         left: 'calc(-50% - 8px)',
//     },
//     track: {
//         height: 15,
//         borderRadius: '8px',
//     },
//     rail: {
//         opacity: 1,
//         height: 15,
//         borderRadius: '8px',
//         backgroundColor: '#F0F0F0',
//     },
//     markLabel: {
//         top: "2.4rem",
//         fontSize: "0.8125rem",
//         color: "#aaa",
//     },
//     "& .MuiSlider-thumb": {
//         display: (props: StyleProps) => props.isVisible ? 'flex' : 'none'
//     }
// })(Slider)

// const useColorlibStepIconStyles = makeStyles({
//     root: {
//         zIndex: 1,
//         color: 'transparent',
//         width: 15,
//         height: 15,
//         display: 'flex',
//         borderRadius: '50%',
//         justifyContent: 'center',
//         alignItems: 'center',
//         position: 'absolute',
//         top: 6,
//         left: '50%',
//         transform: 'translateX(-50%)',
//     },
//     active: {
//         border: '3px solid #4FA9BA',
//         backgroundColor: '#fff',
//     },
//     completed: {
//         // border: '3px solid #4FA9BA',
//         // backgroundColor: '#fff',
//     }
// })

// interface Props {
//     value: number
//     onChange: (scale: number) => void
//     labels: string[]
//     disabled: boolean
//     isFitting?: boolean
//     step?: number
// }

// function ColorlibStepIcon(props: StepIconProps) {
//     const classes = useColorlibStepIconStyles()
//     const { active, completed } = props

//     return (
//         <div
//             className={clsx(classes.root, {
//                 [classes.active]: active,
//                 [classes.completed]: completed,
//             })}
//         >
//             <RadioButtonUncheckedIcon />
//         </div>
//     )
// }

// export default function LikertScaleSeven(props: Props): React.ReactElement {
//     function handleChange(e: any, value: number | number[]) {
//         if (Array.isArray(value)) return

//         props.onChange(value)
//     }
//     console.log('likert', props)

//     const classes = useStyles(props.value - 1 < 0)

//     const itemCount = 6
//     const StepItems = []

//     for (let i = 0; i <= itemCount; i++) {
//         StepItems.push(
//             <Step key={i}>
//                 <StepLabel
//                     className={`${classes.label2} ${i === props.step ? `${classes.labelActive}` : ''}`}
//                     StepIconComponent={ColorlibStepIcon}
//                     onClick={(e) => handleChange(e, i)}
//                 >{`${i - 3}`}</StepLabel>
//             </Step>
//         )
//     }

//     return (
//         <Box className={classes.likertwrap}>
//             <Box className={classes.labels}>
//                 <Box className={classes.label}>
//                     {/* <ChevronLeftIcon /> */}
//                     {/* {props.isFitting ? "타이트함" : props.labels[0]} */}
//                     {props.labels[0]}
//                 </Box>
//                 <Box className={classes.label}>
//                     {props.labels[1]}
//                     {/* {props.isFitting ? "루즈함" : props.labels[1]} */}
//                     {/* <ChevronRightIcon /> */}
//                 </Box>
//             </Box>
//             <CustomSlider
//                 step={1}
//                 min={1}
//                 max={7}
//                 disabled={props.disabled}
//                 getAriaValueText={(value) => `${value - 3}`}
//                 value={props.value}
//                 marks={props.isFitting ? SHOES_MARKS : MARKS}
//                 valueLabelDisplay="off"
//                 // className={props.value === 0 ? "none" : ""}
//                 onChange={handleChange}
//                 isVisible
//             />
//         </Box>
//     )
// }

// const MARKS = range(7).map((n) => ({
//     value: n + 1,
//     label: n + 1,
// }))

// const SHOES_MARKS = range(7).map((n) => ({
//     value: n + 1,
//     label: n - 3,
// }))

// LikertScaleSeven.tsx

import Box from "@material-ui/core/Box"
import Slider from "@material-ui/core/Slider"
import Step from "@material-ui/core/Step"
import { StepIconProps } from "@material-ui/core/StepIcon"
import StepLabel from "@material-ui/core/StepLabel"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"
import clsx from "clsx"
import { range } from "d3"
import React from "react"
interface Props {
    value: number
    onChange: (scale: number) => void
    labels: string[]
    disabled: boolean
    isFitting?: boolean
    step?: number
}

// 메인 컴포넌트 스타일 정의
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: "100%",
            marginBottom: 40,
        },
        root: {
            position: "relative",
            left: "50%",
            transform: "translateX(-50%)",
            width: "112%",
            marginTop: -4,
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: "transparent",
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        label: {
            fontSize: "0.8125rem",
            color: "#666",
            "& svg": {
                width: "0.9rem",
                marginTop: "-2px",
                verticalAlign: "middle",
            },
        },
        likertwrap: {
            position: "relative",
            marginTop: "1.25rem",
            marginBottom: "0",
        },
        labels: {
            display: "flex",
            justifyContent: "space-between",
            "&:before": {
                content: "''",
                position: "absolute",
                zIndex: "10",
                top: "150%",
                left: "0",
                width: "100%",
                height: "1.5rem",
            },
        },
        label2: {
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 3,
            fontSize: 14,
            "& .MuiStepLabel-label": {
                position: "absolute",
                left: "50%",
                bottom: "-46px",
                transform: "translateX(-50%)",
                color: "#999",
            },
            "& .MuiStepLabel-label.MuiStepLabel-completed": {
                color: "#999",
            },
            "& .MuiStepLabel-label.MuiStepLabel-active": {
                color: "#4FA9BA",
            },
        },
        labelActive: {
            color: "#4FA9BA",
        },
    }),
)

// CustomSlider 컴포넌트 스타일 정의
const useCustomSliderStyles = makeStyles({
    root: {
        color: "#4FA9BA",
        marginLeft: "8px",
        width: "calc(100% - 16px)",
        height: 15,
    },
    mark: {
        display: "none",
    },
    thumb: {
        height: 16,
        width: 16,
        backgroundColor: "#fff",
        border: "3px solid currentColor",
        marginTop: 0,
        marginLeft: -8,
        "&:focus, &:hover, &$active": {
            boxShadow: "none",
        },
    },
    thumbHidden: {
        display: "none",
    },
    active: {},
    valueLabel: {
        left: "calc(-50% - 8px)",
    },
    track: {
        height: 15,
        borderRadius: "8px",
    },
    rail: {
        opacity: 1,
        height: 15,
        borderRadius: "8px",
        backgroundColor: "#F0F0F0",
    },
    markLabel: {
        top: "2.4rem",
        fontSize: "0.8125rem",
        color: "#aaa",
    },
})

// CustomSlider 컴포넌트 정의
interface CustomSliderProps extends React.ComponentProps<typeof Slider> {
    isVisible: boolean
}

const CustomSlider: React.FC<CustomSliderProps> = (props) => {
    const { isVisible, ...other } = props
    const classes = useCustomSliderStyles()

    return (
        <Slider
            classes={{
                root: classes.root,
                mark: classes.mark,
                thumb: clsx(classes.thumb, {
                    [classes.thumbHidden]: !isVisible,
                }),
                active: classes.active,
                valueLabel: classes.valueLabel,
                track: classes.track,
                rail: classes.rail,
                markLabel: classes.markLabel,
            }}
            {...other}
        />
    )
}

// StepIcon 스타일 정의
const useColorlibStepIconStyles = makeStyles({
    root: {
        zIndex: 1,
        color: "transparent",
        width: 15,
        height: 15,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 6,
        left: "50%",
        transform: "translateX(-50%)",
    },
    active: {
        border: "3px solid #4FA9BA",
        backgroundColor: "#fff",
    },
    completed: {
        // 필요 시 추가 스타일 정의
    },
})

// Custom Step Icon 컴포넌트 정의
function ColorlibStepIcon(props: StepIconProps) {
    const classes = useColorlibStepIconStyles()
    const { active, completed } = props

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            <RadioButtonUncheckedIcon />
        </div>
    )
}

// LikertScaleSeven 컴포넌트 정의
export default function LikertScaleSeven(props: Props): React.ReactElement {
    // Slider 값 변경 핸들러
    function handleChange(e: any, value: number | number[]) {
        if (Array.isArray(value)) return

        props.onChange(value)
    }

    // useStyles 훅 호출 (매개변수 제거)
    const classes = useStyles()

    const itemCount = 6
    const StepItems = []

    // Step 아이템 생성
    for (let i = 0; i <= itemCount; i++) {
        StepItems.push(
            <Step key={i}>
                <StepLabel
                    className={`${classes.label2} ${
                        i === props.step ? `${classes.labelActive}` : ""
                    }`}
                    StepIconComponent={ColorlibStepIcon}
                    onClick={(e) => handleChange(e, i)}
                >
                    {`${i - 3}`}
                </StepLabel>
            </Step>,
        )
    }

    return (
        <Box className={classes.likertwrap}>
            <Box className={classes.labels}>
                <Box className={classes.label}>{props.labels[0]}</Box>
                <Box className={classes.label}>{props.labels[1]}</Box>
            </Box>
            <CustomSlider
                step={1}
                min={1}
                max={7}
                disabled={props.disabled}
                getAriaValueText={(value) => `${value - 3}`}
                value={props.value}
                marks={props.isFitting ? SHOES_MARKS : MARKS}
                valueLabelDisplay="off"
                onChange={handleChange}
                isVisible={true} // 필요에 따라 동적으로 설정
            />
        </Box>
    )
}

// Marks 정의
const MARKS = range(7).map((n) => ({
    value: n + 1,
    label: n + 1,
}))

const SHOES_MARKS = range(7).map((n) => ({
    value: n + 1,
    label: n - 3,
}))
