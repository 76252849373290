import defaultOuterImage from "../../assets/images/test_shoes_outer.png"
import { Questionnaire } from "../../types"
import { Chip } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import CreateIcon from "@material-ui/icons/Create"
import { scaleOrdinal, schemeTableau10 } from "d3"
import React from "react"
import { Link } from "react-router-dom"

interface StyleProps {
    isSticky: boolean
}

const useStyles = makeStyles(() => ({
    reporttable: {
        width: "100%",
        padding: "1.25rem 1.5625rem 0 1.5625rem",
        borderWidth: (props: StyleProps) => `${props.isSticky ? "0px" : "0 1px 1px 0"}`,
        borderStyle: "solid",
        borderColor: "#ECEBED",
        borderRadius: "0",
        boxShadow: "none",
        "& table": {
            "@media print": {
                display: (props: StyleProps) => `${props.isSticky ? "hidden" : "block"}`,
            },

            "& tbody tr": {
                visibility: (props: StyleProps) => (props.isSticky ? "collapse" : "visible"),
                "@media print": {
                    visibility: (props: StyleProps) => (props.isSticky ? "visible" : "collapse"),
                },
            },
        },

        "& .MuiTableCell-root": {
            fontSize: "0.75rem",
        },

        "& .MuiTableHead-root": {
            "@media print": {
                position: "relative",
            },
        },

        "& .MuiTableRow-head": {
            width: "100% !important",
            // border: '1px solid black',
        },

        "& .MuiTableBody-root": {
            "@media print": {
                // visibility: `${isSticky ? 'table-row-group !important' : 'hidden !important'}`,
                display: (props: StyleProps) =>
                    `${props.isSticky ? "table-row-group !important" : "none !important"}`,
            },
        },

        "& .MuiTableCell-head": {
            width: "12.5rem",
            padding: "0.75rem 1rem",
            borderTop: "1px solid #ccc",
            borderBottomColor: "#ccc",
            backgroundColor: "#f9f9f9",
            fontSize: "0.875rem",

            "& > a": {
                display: "block",
                margin: "0 0 1.25rem",
                "& img": {
                    width: "50%",
                    paddingTop: "1rem",
                },
            },

            "& p": {
                color: "#333",
                fontSize: "0.875rem",
                fontWeight: 500,
                lineHeight: "1.5rem",
                textDecoration: "none",
            },
        },

        "& button": {
            "@media print": {
                display: "none",
            },
        },
    },

    taglist: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",

        "& > *": {
            margin: "0.188rem",
        },
    },
    stickyTable: {
        display: (props: StyleProps) => (props.isSticky ? "none" : ""),
    },
    hiddenTable: {
        display: (props: StyleProps) => (props.isSticky ? "block" : "none !important"),
    },

    collapsedRow: {
        visibility: (props: StyleProps) => (props.isSticky ? "visible" : "collapse"),
    },
}))

interface Props {
    questionnaires: Questionnaire[]
    isSticky: boolean
}

function ProductSummary({ questionnaires, isSticky }: Props): React.ReactElement {
    const classes = useStyles({ isSticky })
    const color = scaleOrdinal<number, string>()
        .domain(questionnaires.map((q) => q.id))
        .range(schemeTableau10)

    return (
        <TableContainer className={classes.reporttable} component={Paper}>
            <Table>
                {isSticky && (
                    <TableHead>
                        <TableRow className={classes.collapsedRow}>
                            <TableCell />
                            {questionnaires.map((q) => (
                                <TableCell key={q.id} align="center">
                                    <Link to={`/questionnaires/${q.id}`}>
                                        <img
                                            // src={q.outerImage || defaultOuterImage}
                                            src={
                                                `https://srl.k2group.co.kr/${q.outerImage}` ||
                                                defaultOuterImage
                                            }
                                            alt=""
                                            style={{ borderTop: `5px solid ${color(q.id)}` }}
                                        />
                                    </Link>
                                    <Button
                                        variant="outlined"
                                        onClick={() =>
                                            (window.location.href = `/admin/main/questionnaire/${q.id}/change/`)
                                        }
                                        size="small"
                                        startIcon={<CreateIcon />}
                                    >
                                        설문지 수정하기
                                    </Button>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                )}
                {!isSticky && (
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                모델명
                            </TableCell>
                            {questionnaires?.map((q) => (
                                <TableCell key={q.id} align="center">
                                    {q.modelName}
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                브랜드
                            </TableCell>
                            {questionnaires?.map((q) => (
                                <TableCell key={q.id} align="center">
                                    {q.brand}
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                시즌
                            </TableCell>
                            {questionnaires?.map((q) => (
                                <TableCell key={q.id} align="center">
                                    {q.season}
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                카테고리
                            </TableCell>
                            {questionnaires?.map((q) => (
                                <TableCell key={q.id} align="center">
                                    {q.category}
                                </TableCell>
                            ))}
                        </TableRow>
                        {questionnaires[0]?.typeName !== undefined &&
                            questionnaires[0]?.typeName === "신발" && (
                                <>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            무게
                                        </TableCell>
                                        {questionnaires?.map((q) => (
                                            <TableCell key={q.id} align="center">
                                                {q.weight} g
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Heel 높이
                                        </TableCell>
                                        {questionnaires?.map((q) => (
                                            <TableCell key={q.id} align="center">
                                                {q.heelHeight} mm
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            forefoot 높이
                                        </TableCell>
                                        {questionnaires?.map((q) => (
                                            <TableCell key={q.id} align="center">
                                                {q.forefootHeight} mm
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            drop 차이
                                        </TableCell>
                                        {questionnaires?.map((q) => (
                                            <TableCell key={q.id} align="center">
                                                {q.dropGap} mm
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </>
                            )}
                        <TableRow>
                            <TableCell component="th" scope="row">
                                설문 안내
                            </TableCell>
                            {questionnaires?.map((q) => (
                                <TableCell key={q.id} align="center">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: (
                                                q.guide || "입력된 설문 가이드가 없습니다."
                                            ).replace(/\n/g, "<br>\n"),
                                        }}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                태그
                            </TableCell>
                            {questionnaires?.map((q) => (
                                <TableCell key={q.id} align="center">
                                    <div className={classes.taglist}>
                                        {q.tags.map((tag) => (
                                            <Chip key={tag} size="small" label={tag} />
                                        ))}
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    )
}

export default React.memo(ProductSummary)
