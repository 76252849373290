import { ReactComponent as DeleteIcon } from "../../assets/images/icon_img_delete.svg"
import { ReactComponent as HelpIcon } from "../../assets/images/tooltip_n.svg"
import { DefinitionItemHelp, DefinitionItemLikertValue } from "../../types"
import HelpDialog from "../HelpDialog"
import LikertScaleSeven from "../LikertScaleSeven"
import QuestionnaireTextField from "../QuestionnaireTextField"
import Box from "@material-ui/core/Box"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React, { ChangeEvent, useEffect, useRef, useState } from "react"

interface ItemLikertProps {
    key?: string
    question: string
    form: "short" | "long"
    labels: string[]
    questionHelp: DefinitionItemHelp
    payloadKey: string
    value: DefinitionItemLikertValue
    focused: boolean
    onChange: (payloadKey: string, value: DefinitionItemLikertValue) => void
    fitting: boolean
}

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        itemBox: {
            position: "relative",
            padding: "20px",
            "&:last-child": {
                marginBottom: "0",
            },
        },
        itemTitle: {
            position: "relative",
            margin: "0 0 1rem",
            "& h3": {
                position: "relative",
                fontWeight: 500,
                lineHeight: "1.4",
            },
        },
        helpIcon: {
            float: "right",
            margin: "4px",
            color: "#cecfd6",
            verticalAlign: "-0.35rem",
        },
        btnWrapper: {
            marginTop: "0",
            display: "flex",
            width: "100%",
            height: "20px",
            justifyContent: "flex-end",
            color: "#000",
            "& button": {
                fontWeight: 600,
            },
        },
        btnWrapper2: {
            marginTop: "0",
            display: "flex",
            width: "100%",
            height: "45px",
            justifyContent: "flex-end",
            alignItems: "end",
            gap: 5,
            "& .MuiInputBase-input": {
                paddingLeft: "12px",
            },
            "& input::placeholder": {
                color: "#999999",
                fontSize: 14,
                opacity: "100%",
            },
            "& button": {
                height: 45,
                border: "1.5px solid #313131",
                padding: "0 20px",
                borderRadius: 10,
                color: "#000",
            },
        },
    }),
)

function ItemLikert(props: ItemLikertProps) {
    const [helpDialog, setHelpDialog] = React.useState(false)
    const [showCommentInput, setShowCommentInput] = React.useState(false)
    const [commentValue, setCommentValue] = React.useState("")
    const ref = useRef(null)
    const classes = useStyles()

    useEffect(() => {
        setCommentValue(props.value.text)
    }, [])

    useEffect(() => {
        if (!props.focused) return

        const element = ref.current as HTMLElement | null
        if (element === null) return

        element.scrollIntoView()
    }, [props.focused, ref])

    function handleScaleChange(newScale: number) {
        const value = {
            scale: newScale,
            text: props.value.text,
        }
        props.onChange(props.payloadKey, value)
    }

    function handleTextChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setCommentValue(e.currentTarget.value)
    }

    function resetCommentInput() {
        const value = {
            scale: props.value.scale,
            text: "",
        }
        props.onChange(props.payloadKey, value)
        setCommentValue("")
        setShowCommentInput(false)
    }

    function handleCommentChange() {
        const value = {
            scale: props.value.scale,
            text: commentValue,
        }
        props.onChange(props.payloadKey, value)
        setShowCommentInput(false)
    }

    return (
        <div key={props.payloadKey}>
            <Box className={classes.itemBox}>
                <Box className={classes.itemTitle}>
                    <Typography variant="subtitle1" component={"h3"} ref={ref}>
                        {props.question}
                        {props.questionHelp.helpText !== "" && (
                            <HelpIcon
                                className={classes.helpIcon}
                                onClick={() => setHelpDialog(true)}
                            />
                        )}
                    </Typography>
                </Box>
                <LikertScaleSeven
                    value={props.value.scale}
                    onChange={handleScaleChange}
                    labels={props.labels}
                    disabled={false}
                    isFitting={props.fitting}
                    // step={props.value.scale}
                />
                {!showCommentInput && commentValue === "" && (
                    <Box className={classes.btnWrapper}>
                        <button type="button" onClick={() => setShowCommentInput(true)}>
                            + Add Comment
                        </button>
                    </Box>
                )}
                {!showCommentInput && commentValue !== "" && (
                    <Box className={classes.btnWrapper} style={{ justifyContent: "space-between" }}>
                        <Typography style={{ fontSize: ".875rem" }}>{props.value.text}</Typography>
                        <button type="button" onClick={resetCommentInput}>
                            <DeleteIcon />
                        </button>
                    </Box>
                )}
                {showCommentInput && (
                    <Box className={classes.btnWrapper2}>
                        <QuestionnaireTextField
                            fullWidth={true}
                            multiline={props.form !== "short"}
                            // rowsMax={props.form === "short" ? 1 : 4}
                            InputLabelProps={{ shrink: true }}
                            // label="이유 (선택 입력)"
                            placeholder="코멘트를 입력해주세요."
                            value={commentValue}
                            onChange={handleTextChange}
                        />
                        <button type="button" onClick={handleCommentChange}>
                            등록
                        </button>
                    </Box>
                )}
                {helpDialog && (
                    <HelpDialog
                        onClose={() => setHelpDialog(false)}
                        title={props.question}
                        help={props.questionHelp}
                    />
                )}
            </Box>
        </div>
    )
}

export default React.memo(ItemLikert)
