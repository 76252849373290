import { setPercentageActive, setPercentageEnv } from "../../features/percentageSlice"
import QuestionnaireTextField from "../QuestionnaireTextField"
import Button from "@material-ui/core/Button"
import InputAdornment from "@material-ui/core/InputAdornment"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
// import { red } from "@material-ui/core/colors"

interface Item {
    key: string
    label: string
    textKey?: string
}

interface Props {
    title: string
    itemList: Item[]
    values: { [index: string]: number | string }
    onChange: (key: string, value: number | string) => void
}

interface EtcPopupProps {
    onChange: (key: string, value: number | string) => void
}

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        section: {
            position: "relative",
            marginTop: "1.5rem",
            lineHeight: "1.5",
            fontWeight: 700,
        },
        itemDesc: {
            paddingLeft: "17px",
            fontSize: "0.875rem",
            fontWeight: 300,
            color: "#888",
        },
        percent: {
            float: "right",
            fontSize: "0.875rem",
        },
        percentNum: {
            fontSize: "1rem",
            color: "#EF6156",
        },
        etcBtn: {
            marginTop: "1.5rem",
            width: "100%",
            height: 45,
            backgroundColor: "#F4F4F4",
            borderRadius: 10,
        },
    }),
)

const useEtcStyles = makeStyles(() =>
    createStyles({
        overlay: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zindex: 100,
        },
        root: {
            width: "100%",
            height: "260px",
            borderRadius: "0 20px 20px 0",
        },
    }),
)

export default function ItemPercentages(props: Props): React.ReactElement {
    const [etc, setEtc] = useState(false)
    const classes = useStyles()
    const dispatch = useDispatch()
    const handlePercentChange = function (value: number, key: string) {
        props.onChange(key, value)
    }
    const handleTextChange = function (value: string, key: string) {
        props.onChange(key, value)
    }
    const sum = props.itemList
        .map((item) => props.values[item.key])
        .filter((value) => typeof value === "number")
        .reduce<number>((a, b) => (+a || 0) + (+b || 0), 0)

    // useEffect(() => {
    //     console.log(props)
    //     if (props.title == "2. 테스트 활동") {
    //         sum !== 100 ? dispatch(setPercentageActive(false)) : dispatch(setPercentageActive(true))
    //     } else {
    //         sum !== 100 ? dispatch(setPercentageEnv(false)) : dispatch(setPercentageEnv(true))
    //     }
    // }, [sum])

    return (
        <>
            <div className={classes.section}>
                <Typography className={classes.percent}>
                    현재 <span className={classes.percentNum}>{sum}</span>{" "}
                    <span style={{ fontSize: ".625rem" }}> %</span>
                </Typography>
                <Typography variant="body1" component={"h3"} style={{ fontWeight: "600" }}>
                    {props.title}
                    <span style={{ color: "#EF6156" }}> *</span>
                    {/* {sum !== 100 && (
                        <Typography className={classes.itemDesc}>
                            합이 정확히 100%이어야 합니다.
                        </Typography>
                    )} */}
                </Typography>
            </div>
            <div>
                {props.itemList.map((item, index) => {
                    if (!item.textKey) {
                        return (
                            <QuestionnaireTextField
                                key={item.key}
                                type="number"
                                fullWidth={true}
                                label={item.label}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    inputProps: { min: 0, max: 100 },
                                }}
                                value={props.values[item.key]}
                                onChange={(e) => {
                                    handlePercentChange(
                                        e.target.value === "" ? 0 : +e.target.value,
                                        item.key,
                                    )
                                }}
                                placeholder=""
                            />
                        )
                    } else {
                        const textKey = item.textKey
                        return (
                            <div key={item.textKey}>
                                <QuestionnaireTextField
                                    type="number"
                                    fullWidth={true}
                                    label={item.label}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">%</InputAdornment>
                                        ),
                                        inputProps: { min: 0, max: 100 },
                                    }}
                                    onChange={(e) => {
                                        handlePercentChange(
                                            e.target.value === "" ? 0 : +e.target.value,
                                            item.key,
                                        )
                                    }}
                                    value={props.values[item.key]}
                                    placeholder="0"
                                />
                                {/* {
                                    !etc && <Button className={classes.etcBtn} onClick={() => setEtc(true)}>
                                        + 기타 항목 추가
                                    </Button>
                                } */}
                                {/* {
                                    etc && <QuestionnaireFormModal />
                                } */}
                            </div>
                        )
                    }
                })}
            </div>
        </>
    )
}
