import ConfirmDialog from "./ConfirmDialog"
import ItemLikert from "./items/ItemLikert"
import ItemMarkOnImages from "./items/ItemMarkOnImages"
import ItemUploads from "./items/ItemUploads"
import QuestionnaireInfo from "./QuestionnaireInfo"
import RespondentProfile from "./RespondentProfile"
import K2Stepper from "./Stepper"
import TestProfile from "./TestProfile"
import {
    User,
    Questionnaire as QuestionnaireData,
    Submission,
    DynamicItem,
    DynamicSection,
    RespondentProfile as RespondentProfileData,
    TestProfile as TestProfileData,
} from "../types"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import React, { useState, useEffect, useRef } from "react"

const useStyles = makeStyles({
    questionnaire: {
        position: "relative",
        overflowY: "scroll",
        height: "100dvh",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    header: {
        // Define any necessary styles for the header
    },
    subHeader: {
        position: "absolute",
        top: "14px",
        left: "10px",
        display: "flex",
        height: "24px",
        zIndex: 20,
        cursor: "pointer",
    },
    titleContents: {
        padding: "66px 20px 20px",
    },
    stepper: {
        padding: "0 20px",
    },
    stepperMargin: {
        padding: "66px 20px 15px",
    },
    innerScroll: {
        position: "fixed",
        left: 0,
        right: 0,
        bottom: 0,
        paddingBottom: 125,
        borderRadius: "30px 30px 0 0",
        backgroundColor: "#fff",
        overflowY: "scroll",
        scrollbarWidth: "none",
        inset: 0,
        overscrollBehavior: "contain",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        "&:before": {
            content: "''",
            position: "fixed",
            left: 0,
            right: 0,
            width: "100%",
            height: "22px",
            borderRadius: "30px 30px 0 0",
            backgroundColor: "#fff",
            zIndex: 12,
        },
        "&:after": {
            content: "''",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "78px",
            backgroundColor: "#fff",
            zIndex: 12,
        },
    },
    innerScroll1: {
        top: "212px",
        "&:before": {
            top: "212px",
        },
    },
    innerScroll2: {
        top: "120px",
    },
    section: {
        margin: "12px 20px",
    },
    questionDesc: {
        marginTop: "0.3125rem",
        color: "#777",
        "& em": {
            color: "#000",
            fontStyle: "normal",
            textDecoration: "underline",
        },
    },
    buttonArea: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        margin: "25px 20px",
        zIndex: 12,
    },
    buttonArea2: {
        display: "grid",
        flexWrap: "wrap",
        gridTemplateColumns: "1fr 1fr",
        columnGap: "10px",
    },
    savebutton: {
        position: "fixed",
        bottom: "6rem",
        right: "1rem",
        zIndex: 10,
    },
    confirmBtn: {
        width: "100%",
        height: 55,
        borderRadius: 10,
        backgroundColor: "#EF6156",
        fontSize: "1rem",
        fontWeight: 600,
        color: "#fff",
        "&:hover": {
            backgroundColor: "#EF6156",
        },
    },
    cancelBtn: {
        backgroundColor: "#F4F4F4",
        color: "#999",
        "&:hover": {
            backgroundColor: "#F4F4F4",
        },
    },
    submitted: {
        color: "#fff",
        position: "absolute",
        top: "-22px",
        right: "calc(50% - 69px)",
        transform: "translateX(50%)",
        fontWeight: "normal",
        zIndex: 11,
    },
    bubble: {
        display: "inline-block",
        position: "relative",
        margin: "0 auto 10px",
        borderRadius: "8px",
        padding: ".25rem .75rem",
        background: "#4FA9BA",
        lineHeight: "1.5",
        "&:after": {
            content: "''",
            position: "absolute",
            left: "15px",
            bottom: "-8px",
            borderTop: "8px solid #4FA9BA",
            borderRight: "6px solid transparent",
            borderLeft: "6px solid transparent",
        },
    },
    // Add any additional classes if necessary
})

// Define Props type
type Props = {
    user: User
    questionnaire: QuestionnaireData
    submission: Submission
    focusedItem: string | null
    onSubmit: (submission: Submission) => void
    onSave: () => void
}

const QuestionnaireFormTestComponent: React.FC<Props> = ({
    user,
    questionnaire,
    submission,
    focusedItem,
    onSubmit,
    onSave,
}) => {
    const classes = useStyles()

    const [step, setStep] = useState<number | null>(null)
    const [stepMax, setStepMax] = useState<number>(0)
    const [stepTitleArr, setStepTitleArr] = useState<string[]>([])
    const [submissionState, setSubmissionState] = useState<Submission>(submission)
    const [confirmDialog, setConfirmDialog] = useState<boolean>(false)

    const prevStepRef = useRef<number | null>(null)

    useEffect(() => {
        if (questionnaire) {
            const tempArr = questionnaire.dynamic_data.map((data) => data.name)

            let initialStepTitleArr: string[]
            if (questionnaire.typeName === "신발") {
                initialStepTitleArr = ["응답자 프로파일 확인", "이미지 업로드", "테스트 프로파일"]
            } else {
                initialStepTitleArr = ["응답자 프로파일 확인", "이미지 업로드", "테스트 프로파일"]
            }

            initialStepTitleArr.splice(1, 0, ...tempArr)
            setStepTitleArr(initialStepTitleArr)
            setStepMax(initialStepTitleArr.length)
        }
    }, [questionnaire])

    useEffect(() => {
        return () => {
            document.body?.classList.remove("noscroll")
        }
    }, [])

    useEffect(() => {
        if (prevStepRef.current !== step) {
            const scroller = document.getElementById("scroller")
            scroller?.scrollTo(0, 0)
        }
        prevStepRef.current = step
    }, [step])

    const handleSetStep = (actionType: string) => {
        let newStep = step

        if (actionType === "next") {
            if (newStep === null) {
                newStep = 0
            } else {
                newStep += 1
            }
        } else if (actionType === "prev") {
            if (newStep !== null) {
                newStep -= 1
            }
        }

        if (newStep === stepMax) {
            newStep -= 1
            handleSubmit()
        } else if (newStep === -1) {
            newStep = null
        }

        document.body?.classList.toggle("noscroll", newStep !== null)
        setStep(newStep)
    }

    const handlePayloadChange = (key: string, value: any) => {
        setSubmissionState((prev) => ({
            ...prev,
            payload: {
                ...prev.payload,
                [key]: value,
            },
        }))
    }

    const handleSaveCheck = () => {
        const savedQuestionnaire = JSON.parse(localStorage.getItem("saveQuestionnaire") || "{}")
        if (
            localStorage.getItem("saveQuestionnaire") &&
            questionnaire.id !== savedQuestionnaire.questionnaireId
        ) {
            setConfirmDialog(true)
        } else {
            onSave()
            localStorage.setItem("saveQuestionnaire", JSON.stringify(submissionState))
        }
    }

    const handleConfirm = () => {
        onSave()
        localStorage.setItem("saveQuestionnaire", JSON.stringify(submissionState))
        setConfirmDialog(false)
    }

    const handleSubmit = () => {
        const saved = JSON.parse(localStorage.getItem("saveQuestionnaire") || "{}")
        if (Object.keys(saved).length !== 0) {
            localStorage.removeItem("saveQuestionnaire")
        }
        onSubmit({ ...submissionState })
    }

    const handleUploadChange = (key: string, uploadingImages: any, uploadedImages: any) => {
        let newProofImages = submissionState.proofImages
        let newUsageImages = submissionState.usageImages

        if (key === "E:1") {
            newProofImages = uploadedImages
        } else {
            newUsageImages = uploadedImages
        }
        const newUploads = {
            ...submissionState.uploads,
            [key]: uploadingImages,
        }
        console.log("newUploads", newUploads)
        setSubmissionState((prev) => ({
            ...prev,
            proofImages: newProofImages,
            usageImages: newUsageImages,
            uploads: newUploads,
        }))
    }

    const handleRespondentProfileChange = (profile: RespondentProfileData) => {
        setSubmissionState((prev) => ({
            ...prev,
            respondentProfile: profile,
        }))
    }

    const handleTestProfileChange = (profile: TestProfileData) => {
        setSubmissionState((prev) => ({
            ...prev,
            testProfile: profile,
        }))
    }

    const userInfo = (users: User) => {
        if (users.empnum) {
            return `${users.username} - ${users.firstName}`
        } else {
            return users.username
        }
    }

    const renderSection = (
        id: string,
        section: DynamicSection,
        questionnaire: QuestionnaireData,
    ): React.ReactElement => {
        const { payload } = submissionState
        const sectionLength = section.data.length

        return (
            <div key={id}>
                {section.description && (
                    <Typography
                        className={classes.questionDesc}
                        gutterBottom
                        style={{ padding: "0 20px" }}
                    >
                        <span dangerouslySetInnerHTML={{ __html: section.description }} />
                    </Typography>
                )}
                {section.data.map((item, num) => {
                    const key = `${section.section}:${num + 1}`
                    const i = item as DynamicItem
                    const fitting = section.name.includes("피팅")
                    if (section.section !== "B") {
                        return (
                            <div key={key}>
                                <ItemLikert
                                    payloadKey={key}
                                    question={i.question}
                                    form={"long"}
                                    labels={[i.min_text, i.max_text]}
                                    questionHelp={{
                                        helpImages: [i.helpImages],
                                        helpText: i.helpText,
                                    }}
                                    focused={key === focusedItem}
                                    value={payload[key]}
                                    onChange={handlePayloadChange}
                                    fitting={fitting}
                                />
                                {sectionLength - 1 !== num && <div className="divider"></div>}
                            </div>
                        )
                    } else {
                        const images = [
                            {
                                key: "outerImage",
                                label: "이미지1",
                            },
                            {
                                key: "innerImage",
                                label: "이미지2",
                            },
                            {
                                key: "soleImage",
                                label: "이미지3",
                            },
                        ]
                        return (
                            <div className={classes.section} key={images[num].key}>
                                <ItemMarkOnImages
                                    key={num}
                                    payloadKey={key}
                                    questionnaire={questionnaire}
                                    question={i.question}
                                    images={images}
                                    value={payload[key]}
                                    onChange={handlePayloadChange}
                                />
                            </div>
                        )
                    }
                })}
            </div>
        )
    }

    return (
        <div className={`${classes.questionnaire} ${questionnaire.closed ? "closed" : ""}`}>
            <div className={classes.header}>
                <div>
                    <Typography
                        className={classes.subHeader}
                        onClick={() => {
                            console.log("history back")
                            window.history.back()
                        }}
                    >
                        <ChevronLeftIcon />
                        {step === null ? "설문상세" : stepTitleArr[step]}
                    </Typography>
                </div>
                {step === 0 && (
                    <div className={classes.titleContents}>
                        <Typography variant="h6">{user && userInfo(user)} 님,</Typography>
                        <Typography variant="body2">
                            최근에 입력한 프로파일을 확인해주세요.
                        </Typography>
                        <Typography variant="body2">
                            이전과 동일한 경우 다음을 눌러주세요.
                        </Typography>
                    </div>
                )}
                {(step === 0 || step !== null) && (
                    <div
                        className={`${classes.stepper} ${step !== 0 ? classes.stepperMargin : ""}`}
                    >
                        <K2Stepper step={step} stepMax={stepMax} />
                    </div>
                )}
            </div>
            {step === null && (
                <QuestionnaireInfo questionnaire={questionnaire} className={`stepContent`} />
            )}
            {step === 0 && questionnaire.typeName === "신발" && (
                <div className={`${classes.innerScroll} ${classes.innerScroll1}`}>
                    <div className={`stepContent`}>
                        {submissionState.respondentProfile && (
                            <RespondentProfile
                                profile={submissionState.respondentProfile}
                                onChange={handleRespondentProfileChange}
                            />
                        )}
                    </div>
                </div>
            )}
            {step === 0 && questionnaire.typeName !== "신발" && (
                <div className={`${classes.innerScroll} ${classes.innerScroll1}`}>
                    <div className={`stepContent`}>
                        {submissionState.respondentProfile && (
                            <RespondentProfile
                                profile={submissionState.respondentProfile}
                                onChange={handleRespondentProfileChange}
                            />
                        )}
                    </div>
                </div>
            )}
            {!!step && step !== 0 && (
                <div id="scroller" className={`${classes.innerScroll} ${classes.innerScroll2}`}>
                    {questionnaire.dynamic_data.map((section, idx) => (
                        <div className={`stepContent`} key={section.id}>
                            {step === idx + 1 &&
                                renderSection(section.id.toString(), section, questionnaire)}
                        </div>
                    ))}
                    {step === questionnaire.dynamic_data.length + 1 && (
                        <div
                            className={`stepContent stepContent${questionnaire.dynamic_data.length}`}
                        >
                            <ItemUploads
                                payloadKey={"E:1"}
                                question={"1. 활동 인증 스크린샷을 업로드 해주세요"}
                                questionDesc={"(최대 10개 / 30MB까지 첨부가능)"}
                                maxImages={10}
                                uploadingImages={submissionState.uploads["E:1"]}
                                uploadedImages={submissionState.proofImages}
                                onChange={handleUploadChange}
                            />
                            <div className="divider"></div>
                            <ItemUploads
                                payloadKey={"E:2"}
                                question={"2. 제품의 손상 정도를 보여주는 이미지를 업로드 해주세요"}
                                questionDesc={"(최대 10개 / 30MB까지 첨부가능)"}
                                maxImages={10}
                                uploadingImages={submissionState.uploads["E:2"]}
                                uploadedImages={submissionState.usageImages}
                                onChange={handleUploadChange}
                            />
                        </div>
                    )}
                    {step === questionnaire.dynamic_data.length + 2 && (
                        <div
                            className={`stepContent stepContent${questionnaire.dynamic_data.length}`}
                        >
                            <TestProfile
                                profile={submissionState.testProfile}
                                onChange={handleTestProfileChange}
                            />
                        </div>
                    )}
                </div>
            )}
            <div className={`${classes.buttonArea} ${step !== null ? classes.buttonArea2 : ""}`}>
                {!questionnaire.submitted && (
                    <Button
                        className={classes.savebutton}
                        variant="contained"
                        size="large"
                        disabled={questionnaire.closed}
                        onClick={handleSaveCheck}
                    >
                        임시저장
                    </Button>
                )}
                {step !== null ? (
                    <>
                        <Button
                            type="button"
                            className={`${classes.cancelBtn} ${classes.confirmBtn}`}
                            onClick={() => handleSetStep("prev")}
                        >
                            이전
                        </Button>
                        <Button
                            type="button"
                            className={`${classes.confirmBtn}`}
                            onClick={() => handleSetStep("next")}
                        >
                            다음
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            type="button"
                            className={`${classes.confirmBtn} ${
                                questionnaire.closed ? classes.cancelBtn : ""
                            }`}
                            onClick={() => {
                                if (questionnaire.closed) {
                                    alert(
                                        "이 설문은 이미 마감되어 설문할 수 없습니다. 다른 설문을 시작해 주세요.",
                                    )
                                } else {
                                    handleSetStep("next")
                                }
                            }}
                        >
                            <div className={classes.submitted}>
                                <div className={classes.bubble}>
                                    {`${questionnaire.submitted_count}명이 설문했어요!`}
                                </div>
                            </div>
                            설문 시작하기
                        </Button>
                    </>
                )}
            </div>
            {confirmDialog && (
                <ConfirmDialog
                    description="임시저장한 다른 내역이 있습니다. 삭제하고 덮어씌우시겠습니까?"
                    onClose={() => setConfirmDialog(false)}
                    onConfirm={handleConfirm}
                />
            )}
        </div>
    )
}

export default QuestionnaireFormTestComponent
